.tweets-container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    height: 82vh;
    overflow-y: auto;
    scroll-behavior: smooth;
    padding-left: 10px;
    padding-top: 20px;
}

.tweets-container .blog {
    transform: scale(1);
    transition-duration: 00.3s;
    margin-right: 15px;
    box-shadow: 1px 2px 10px  rgba(59, 59, 59, 0.2);
    transition: 0.3s;
    width: 420px;
    border-radius: 15px;
    margin-bottom: 10px;
    cursor: pointer;
    height: fit-content;
    overflow-y: hidden;
    background-image: linear-gradient(140deg, #ffffff 0%, #ecf4ff 50%, #e0fbff 75%);
}

.tweets-container .blog:hover{
    transform: scale(1);
    transition-duration: 0.3s;
}

.tweets-container .image img{
    margin-top: 10px;
    width: 100%;
    align-items: center;
    border-radius: 5px 5px 5px 5px;
}

.btn-for-tweet {
    margin-top: 10px;
    text-align: center;
    background-color: #00687f;
    width: 40%;
    cursor: pointer;
    font-size: 15px !important;
    
  }
  
  .btn-one-for-tweet {
    color: rgba(234, 205, 205, 0.808) !important;
    transition: all 0.3s;
    position: relative;
  }
  .btn-one-for-tweet span {
    transition: all 0.3s;
    color: white;
  }
  .btn-one-for-tweet::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-color: rgba(255,255,255,0.5);
    border-bottom-color: rgba(255,255,255,0.5);
    transform: scale(0.1, 1);
  }
  .btn-one-for-tweet:hover span {
    letter-spacing: 2px;
  }
  .btn-one-for-tweet:hover::before {
    opacity: 1; 
    transform: scale(1, 1); 
  }
  .btn-one-for-tweet::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.3s;
    background-color: rgba(255, 255, 255, 0.237);
  }
  .btn-one-for-tweet:hover::after {
    opacity: 0; 
    transform: scale(0.1, 1);
  }
  
.header{
    padding: 7px;
    font-weight: 500;
    color: rgb(17, 16, 16);
    border-bottom-style: solid;
    border-bottom-width: 3px;
    border-bottom-left-radius: 2px;
}

.body{
    padding: 10px;
    text-align: left;
    padding-bottom: 20px !important;
}

.tweets-container .contain{
    font-size: 15px;
    color: rgb(0, 0, 0);
    overflow: hidden;
    padding: 1px;
    margin-top: 5px;
    /* display: none; */
}

.footer button{
    float: right;
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: rgba(145, 76, 210, 0.703);
    box-shadow: 1px 1px 5px rgb(204, 204, 204);
    color: white;

} 

.footer{
    padding-top: 20px;
    color:rgb(0, 0, 0);
    /* display: none; */
}
.footer span{
    float: right;
}

@media only screen and (max-width: 600px){
    .tweets-container{
    width: 100%;
    margin-left: 0;
    }
    .tweets-container .blog{
        width: 360px;
    }

}