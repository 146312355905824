:root {
  --primaryColor: #5993e2;
  --HoverColor: hsl(224, 92%, 76%);
  --paleBlue: #c2d6f5;
  --whiteColor: #fff;
  --blackColor: #000;
  --textColor: #333;
  --bgColor: #f5f5f5;
  --greyText: #777;
  --inputColor: hsl(330, 12%, 96%);
  --itemCardColor: hsl(120, 5%, 88%);
  --itemCardHoverColor: hsl(120, 27%, 87%);
  /* Font and Typography */
  --biggestFontSize: 2.5rem;
  --h1FontSize: 1.5rem;
  --h2FontSize: 1.25rem;
  --h3FontSize: 1rem;
  --normalFontSize: 0.9rem;
  --smallFontSize: 0.8rem;
  --smallerFontSize: 0.7rem;
  --smallestFontSize: 0.6rem;
}

.listingSection {
  flex-basis: 130%;
  margin-top: 1rem;
}
.listingSection .heading {
  width: 100%;
  justify-content: space-between;
}
.listingSection .heading h1 {
  font-size: var(--h2FontSize);
  color: var(--blackColor);
  font-weight: 700;
}
.listingSection .secContainer .tweetOption {
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.listingSection .secContainer .tweetOption .bar-active {
  color: #005288;
  background-color: #c2d6f5;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0.5rem;
  font-weight: 700;
  cursor: pointer;
}
.listingSection .secContainer .tweetDiv {
  max-height: 54vh;
  overflow: auto;
  border-radius: 1rem;
  border: 1px solid var(--itemCardColor);
}/*# sourceMappingURL=listing.css.map */