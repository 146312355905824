:root {
    --primaryColor: #5993e2;
    --HoverColor: hsl(224, 92%, 76%);
    --paleBlue: #c2d6f5;
    --whiteColor: #fff;
    --blackColor: #000;
    --textColor: #333;
    --bgColor: #f5f5f5;
    --greyText: #777;
    --inputColor: hsl(330, 12%, 96%);

    /* Font and Typography */
    --biggestFontSize: 2.5rem;
    --h1FontSize: 1.5rem;
    --h2FontSize: 1.25rem;
    --h3FontSize: 1rem;
    --normalFontSize: 0.9rem;
    --smallFontSize: 0.8rem;
    --smallerFontSize: 0.7rem;
}

.topSection {
    .headerSection {
        justify-content: space-between;

        .title1 {
            h1 {
                font-size: var(--h1FontSize);
                color: var(--blackColor);
                font-weight: 700;
            }

            p {
                font-size: var(--normalFontSize);
                color: var(--textColor);
                font-weight: 500;
            }
        }

        .searchBar {
            padding: 1rem 1rem;
            background: var(--whiteColor);
            border-radius: 5px;
            gap: 1rem;
            box-shadow: 0 2px 4px var(--inputColor);

            input {
                border: none;
                outline: none;
                background: none;
            }

            .icon {
                color: var(--textColor);
                &:hover {
                    color: var(--primaryColor);
                }
            }
        }

        .adminDiv {
            gap: 0.5rem;
            .icon {
                font-size: var(--biggestFontSize);
                background: transparent;
                border-radius: 5px;
                padding: 5px;
                box-shadow: 0 2px 4px var(--inputColor);
                color: var(--textColor);
            }
            p {
                font-size: var(--h2FontSize);
                color: var(--textColor);
                font-weight: 700;
            }

            .adminImage {
                border: 3px solid var(--whiteColor);
                border-radius: 10px;
                width: 2.5rem;
                overflow: hidden;
                box-shadow: 0 2px 4px var(--inputColor);

                img {
                    width: 100%;
                    height: 100%;
                    box-radius: 10px;
                    object-fit: cover;
                }
            }
        }
    }

    .cardSection {
        margin-top: 1rem;
        gap: 1rem;

        .rightCard {
            position: relative;
            flex-basis: 80%;
            height: 150px;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            margin: auto;
            padding: 2rem;
            border-radius: 1rem;
            box-shadow: 0 2px 4px var(--greyText);
            overflow: hidden;

            .videoDiv {
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            h1,
            p {
                z-index: 100;
            }

            h1 {
                font-size: var(--h1FontSize);
                color: var(--whiteColor);
                font-weight: 800;
            }

            p {
                color: var(--whiteColor);
                font-weight: 500;
            }
        }

        .leftCard {
            flex-basis: 10%;
            .mainStat {
                position: relative;
                padding: 1rem;
                height: 150px;
                border-radius: 1rem;

                justify-content: space-between;
                box-shadow: 0 2px 4px var(--greyText);

                &::before {
                    content: "";
                    position: absolute;
                    height: 100%;
                    width: 95%;

                    left: 0;
                    bottom: 0;
                    border-radius: 1rem;
                }

                .textDiv {
                    z-index: 100;
                    h1 {
                        font-size: var(--h1FontSize);
                        padding-bottom: 0.5rem;
                        color: var(--blackColor);
                    }

                    .flex {
                        gap: 1rem;
                        font-weight: 500;
                        color: var(--blackColor);

                        small {
                            font-size: var(--normalFontSize);
                            display: block;
                            color: var(--primaryColor);
                            font-weight: 500;
                            padding: 0.5rem 0;
                        }
                    }

                    .link {
                        margin-top: 1rem;
                        cursor: pointer;

                        &:hover {
                            color: var(--primaryColor);
                            .icon {
                                transform: translateX(10px);
                                transition: 0.3s ease-in-out;
                            }
                        }
                    }
                }

                .imgDiv {
                    transform: translateY(-20px);

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 1rem;
                    }
                }
            }
        }
    }
}
