:root {
  --primaryColor: #5993e2;
  --HoverColor: hsl(224, 92%, 76%);
  --paleBlue: #c2d6f5;
  --whiteColor: #fff;
  --blackColor: #000;
  --textColor: #333;
  --bgColor: #f5f5f5;
  --greyText: #777;
  --inputColor: hsl(330, 12%, 96%);
  --itemCardColor: hsl(120, 5%, 88%);
  --itemCardHoverColor: hsl(120, 27%, 87%);
  /* Font and Typography */
  --biggestFontSize: 2.5rem;
  --h1FontSize: 1.5rem;
  --h2FontSize: 1.25rem;
  --h3FontSize: 1rem;
  --normalFontSize: 0.9rem;
  --smallFontSize: 0.8rem;
  --smallerFontSize: 0.7rem;
  --smallestFontSize: 0.6rem;
}

.activitySection {
  flex-basis: 50%;
  margin-top: 1rem;
}
.activitySection .heading {
  width: 100%;
  justify-content: space-between;
}
.activitySection .heading h1 {
  font-size: var(--h2FontSize);
  color: var(--blackColor);
  font-weight: 700;
}
.activitySection .secContainer {
  margin-top: 1rem;
  max-height: 59vh;
  height: 59vh;
  overflow: auto;
  border-left: 2px solid var(--itemCardColor);
}
.activitySection .secContainer .singleCustomer {
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.5rem;
}
.activitySection .secContainer .singleCustomer img {
  width: 40px;
  height: 40px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 2px 4px var(--itemCardColor);
  margin-right: 1rem;
}
.activitySection .secContainer .singleCustomer .customerDetails {
  flex: 1;
}
.activitySection .secContainer .singleCustomer .customerDetails .name {
  font-size: var(--h3FontSize);
  font-weight: 600;
  color: var(--blackColor);
  display: block;
}
.activitySection .secContainer .singleCustomer .customerDetails small {
  font-weight: 500;
  color: var(--textColor);
  font-size: var(--smallFontSize);
}
.activitySection .secContainer .singleCustomer .duration {
  color: var(--textColor);
  font-weight: 500;
  font-size: var(--smallestFontSize);
}/*# sourceMappingURL=activity.css.map */