:root {
    --primaryColor:  #5993e2;
    --HoverColor: hsl(224, 92%, 76%);
    --paleBlue: #c2d6f5;
    --whiteColor: #fff;
    --blackColor: #000;
    --textColor: #333;
    --bgColor: #f5f5f5;
    --greyText: #777;
    --inputColor: hsl(330, 12%, 96%);
    --itemCardColor: hsl(120, 5%, 88%);
    --itemCardHoverColor: hsl(120, 27%, 87%);
  
  
    /* Font and Typography */
    --biggestFontSize: 2.5rem;
    --h1FontSize: 1.5rem;
    --h2FontSize: 1.25rem;
    --h3FontSize: 1rem;
    --normalFontSize: 0.9rem;
    --smallFontSize: 0.8rem;
    --smallerFontSize: 0.7rem;
    --smallestFontSize: 0.6rem;
  }



.parentCardDiv{
    margin: .7rem;
    background: var(--whiteColor);
    border-radius: 10px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    max-width: 100%;
    overflow: auto;
    max-height: 210px;
    min-height: 150px;
}

.parentCardDiv h6{
    opacity: .6;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.parentCardDiv h2{
    letter-spacing: 1px;
    margin: 10px 0;
}

.childCardDiv1{
   
    background: var(--HoverColor);
    color: var(--whiteColor);
    min-width: 200px;
    max-width: 200px;
    word-break: keep-all;
    padding: 10px;
    width: 200px;
    position: relative;
    
}

.childCardDiv1 a {
    position: absolute;
    margin-top: 20px;
    opacity: .6;
    transition: .5s ease-in-out;
}

.childCardDiv1 a:hover {
    color: #005288;
    padding: .5rem 1.2rem;
    border-radius: 20px;
    background: var(--paleBlue);
}

.childCardDiv2 {
    padding: 20px;
    position: relative;
    width: 100%;
}

.progress-wrapper{
    position: absolute;
    top: 30px;
    right: 30px;
    text-align: right;
    width: 150px;
}

.progress{
    background: #ddd;
    border-radius: 30px;
    height: 5px;
    width: 100%;
}

/* .progress::after{
    content: "";
    border-radius: 3px;
    background: #5993e2;
    position: absolute;
    left: 0;
    top: 0;
    height: 5px;
    width: 10%;
} */

.progress-text{
    font-size: 10px;
    opacity: 1.3;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.childCardDiv2 p {
    font-size: 12px;
    font-weight: bold;
}





.addButton{
    background: var(--primaryColor);
    border: 1px solid transparent;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    color: #fff;
    font-size: 12px;
    padding: 8px 16px;
    position: absolute;
    bottom: 10px;
    right: 20px;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all .4s ease-in-out;
    font-weight: bold;
    border-radius: 20px;
}


.addButton:hover{
    background: #fff;
    color: var(--primaryColor);
    transform: scale(1.05);
}
