.disp{
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 20px;
}

.blog-body{
    width: 400px;
    /* background-image: url('https://images.unsplash.com/photo-1481026469463-66327c86e544?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=908&q=80'); */
    background-image: linear-gradient(140deg, #ffffff 0%, #e4efff 50%, #e0fbff 75%);
    border-style: solid;
    border-width: 3px !important;
    border-color: rgb(154, 207, 215);
    border-radius: 20px;
    padding-bottom: 40px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 600px;
    box-shadow: 2px 5px 10px  rgb(174, 174, 174);
}

.blog-body div{
    display: block;
}

.blog-body div img{
    max-height: 300px;
    width: 100%;
    border-radius: 20px;
    margin-bottom: 20px;
    
}

.blog-body .theader{
    display: flex;
    flex-direction: column;
    font-size: 20px;
    padding: 6px 20px;
    border-right: 20px;
    border-right-color: blue;
    font-weight: 500;
    color: white;
    background-color: rgba(0, 0, 0, 0.785);
    border-radius: 10px;
    margin-bottom: 10px;
    display: block;
    width: 300px;
    flex-wrap: wrap;
    overflow-x: scroll;
    scrollbar-width: 0;
    -ms-overflow-style: none; 
}

.blog-body .theader::-webkit-scrollbar {
    display: none;
}

.blog-body body{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.tblog_contain{
    padding: 20px;
    margin-left: 10px;
    color: black;
    width: 300px;
    height: 80% !important;
    overflow: hidden;
}


.blog-body .view-btn {
    height: 40px;
    width: 150px;
    margin-top: 30px;
    background-color: transparent;
    border: 2px solid rgb(0, 137, 139);
    font-weight: 600;
    border-radius: 10px;
}

.blog-body view-btn:hover{
    background-color: rgb(0, 137, 139);
    color: white;
    cursor: pointer;
}


.action-bar .del{
    color: gray ;
}

.action-bar .edit{
    color: gray ;
}

.action-bar{
    display: flex;
    flex-direction: row;
}

.action-bar .edit:hover{
    color:cornflowerblue;
}

.action-bar .del:hover{
    color:brown;
    cursor: pointer;
}

@media only screen and (max-width: 600px){
    .blog-body{
        width: 90%;
        padding: 10px;
        margin-bottom: 40px !important;
    }
}
