.mainContent{
    width: 82%;
    height: 100%;
    padding: 2rem;
    overflow: auto;

    .bottom {
        nargin-top: 2rem;
        gap: 2rem;
        align-items: flex-start;
    }
}