@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Pacifico&display=swap');






a {
  text-decoration: none;
}

li {
  list-style: none;
}

/* 
 

/* navbar style start  */

.main-nav {
  width: 100%;
  height: 4.5rem;
  display: grid;
  grid-template-columns: 4.5rem 1fr 1fr 0fr 3rem;
  box-shadow: rgba(80, 80, 80, 0.25) 0px 10px 20px -20px,
    rgba(94, 94, 94, 0.3) 0px 20px 40px -30px;
}

.logo {
  display: flex;
  align-items: center;
  padding-left: 10px;
  height: 4rem;
  font-family: 'Jost';
  /* background: #383838; */
  border-radius: 0 15px 15px 10px;
  /* margin: 5px; */
  max-width: 50px;
  height: 70px;
}

.logo .icon-s{
  font-size: 1.2rem;
  display: inline;
  color: rgb(0, 6, 87);
  font-weight: 400;
  text-transform: uppercase;
  padding: 5px;

}

.main-nav .logo h2 {
  font-size: 25px;
  font-weight: 400;
  text-transform: uppercase;
}

.main-nav .logo h2 span {
  font-size: 40px;
}

.main-nav .logo .icon-h{
  font-size: 40px;
  display: inline;
  color: white;
  background-color: rgb(0, 6, 87);
  padding: 0 10px 0 10px; 
  border-radius: 5px;
  font-family: 'Pacifico';
  
}

.menu-link {
 
  grid-column: 3/4;
}

.menu-link ul {
  height: 4rem;
  display: flex;
  justify-content: center;
  gap: 2rem;
  align-items: center;
}

.menu-link ul li a{
  font-size: 20px;
  font-weight: 500;
}

.social-media {
  grid-column: 4/5;
}

.social-media ul {
  height: 4rem;
  display: grid;
  grid-template-columns: 3fr repeat(3, 1fr);
  align-items: center;
  justify-content: flex-end;
}

.social-media ul li {
  text-align: right;
}

.social-media ul li:first-child {
  grid-column: 2/3;
}

/* ----------- Grid part ends ----------------- */

.logo h2 {
  font-size: 2rem;
  font-weight: 400;
  text-transform: uppercase;
}

.logo h2 span {
  font-size: 3.5rem;
}

.menu-link ul li {
  font-size: 1.8rem;
}

.menu-link ul li a {
  text-transform: capitalize;
  color: rgba(0, 0, 3, 0.8);
  transition: 0.5s;
  text-decoration: none;
}

.menu-link ul li:hover > a {
  transform-origin: left;
  color: rgba(0, 0, 3, 1);
  text-decoration: none;
  transition: 0.5s;
  /* text-shadow: 0 0 0.5rem rgba(0, 0, 3, 0.8); */
  letter-spacing: 0.1rem;
}

.social-media ul li {
  font-size: 1.8rem;
}

.social-media .hamburger-menu {
  display: none;
}

.twitter {
  color: #4370d0;;
}

.instagram {
  color: #c32aa3;
  display: none;
}

.youtube {
  color: #ff0000;
  display: none;
}

/* hero section   */

.hero-section {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hero-section p {
  font-size: 3rem;
  text-transform: capitalize;
}

.hero-section h1 {
  font-size: 5rem;
  text-transform: uppercase;
  text-align: center;
}

/* responsive css style  */
@media (max-width: 1080px) {
  .main-nav {
    height: 6rem;
    grid-template-columns: 6rem 2fr 2fr 0fr 5rem;
  }

  .menu-link ul,
  .social-media ul {
    height: 8rem;
  }
}

/* responsive css style  */
@media (max-width: 998px) {
  .main-nav {
    height: 7rem;
    grid-template-columns: 6rem 2fr 3fr 2rem 2rem;
  }

  .menu-link {
    display: none;
  }

  .social-media ul {
    height: 7rem;
  }

  .mobile-menu-link {
    grid-column: 2/4;
    position: relative;
    z-index: 99;
  }

  .mobile-menu-link {
    background-color: white;
    height: 12rem;
    display: grid;
    grid-column: 2/5;
    align-items: center;
    padding-left: 2rem;
    transition: all 2s linear;
    transform-origin: top;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .mobile-menu-link ul {
    height: 8rem;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: start;
  }

  .mobile-menu-link ul li:first-child {
    transition-delay: 0.2s;
  }

  .social-media {
    grid-row: 1/2;
    grid-column: 3/5;
    justify-items: end;
    align-items: center;
    transition: all 2s linear;
  }

  .social-media .social-media-desktop {
    height: 0;
    display: none;
  }

  .social-media {
    height: 7rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }
}

@media (max-width: 798px) {
  .main-nav {
    height: 6rem;
    grid-template-columns: 5rem 2fr 1fr 1fr 1rem;
  }


  .social-media ul {
    height: 6rem;
  }

  .social-media {
    height: 6rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }
}

@media (max-width: 520px) {
  .main-nav {
    height: 6rem;
  }

  .social-media ul {
    height: 6rem;
  }

  

  .social-media {
    height: 6rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }

  /* hero section  */

  .hero-section h1 {
    font-size: 3.8rem;
  }
}
