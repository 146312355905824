:root {
    --primaryColor:  #5993e2;
    --HoverColor: hsl(224, 92%, 76%);
    --paleBlue: #c2d6f5;
    --whiteColor: #fff;
    --blackColor: #000;
    --textColor: #333;
    --bgColor: #f5f5f5;
    --greyText: #777;
    --inputColor: hsl(330, 12%, 96%);
  
  
    /* Font and Typography */
    --biggestFontSize: 2.5rem;
    --h1FontSize: 1.5rem;
    --h2FontSize: 1.25rem;
    --h3FontSize: 1rem;
    --normalFontSize: 0.9rem;
    --smallFontSize: 0.8rem;
    --smallerFontSize: 0.7rem;
  }


  .sideBar {
    background: var(--whiteColor);
    
    width: 18%;
    height: 100%;
    gap: 2.5rem;
    overflow: auto;

    .logoDiv {
      padding: 1.5rem 1.5rem 0;
      cursor: pointer;

      img{
        max-width: 30px;
        margin-right: .5rem;
        border-radius: 2px;
        padding: .2rem;
      }

      h2 {
        font-size: var(--h2FontSize);
        color: var(--primaryColor);
      }
    }

    .menuDiv {
      .divTitle{
        font-size: var(--h3FontSize);
        color: var(--blackColor);
        padding: 0 1.5rem 1.5rem;
      }

      .menuLists{
        .listItem{
          padding: .2rem 1.5rem;
          width: 100%;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            width: 5px;
            height: 0%;
            bottom: 0;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            background: var(--primaryColor);
          }

          .menuLink{
            color: var(--greyText);
            font-weight: 500;
            transition: 0;
            .icon{
              margin-right: .5rem;
            }
          }

          &:hover .menuLink{
            color: var(--primaryColor);
            transition: 0;
          }

          &:hover::before{
            height: 100%;
            transition: .3s ease;
          }
        }
      }
    }
    .sideBarCard{
      width: 100%;
      padding: 1rem;
      text-align: center;
      position: relative;

      .icon{
        position: absolute;
        background: var(--HoverColor);
        border: 10px solid var(--whiteColor);
        font-size: 3rem;
        border-radius: 50%;
        top: -8px;
        right: 50%;
        transform: translateX(50%);
        z-index: 100;
      }

      .cardContent{
        position: relative;
        padding: 1rem;
        background: var(--HoverColor);
        border-radius: 10px;
        overflow: hidden;

        h3{
          font-size: var(--h3FontSize);
          margin-top: 1rem;
          font-weight: 800;
          color: var(--blackColor)
        }

        p{
          font-size: var(--normalFontSize);
          color: var(--textColor);
          padding-bottom: 1rem;
          font-weight: 500;
        }

        .btn1{
          position: relative;
          color: var(--textColor);
          
        }

        .circle1, .circle2{
          position: absolute;
          background: var(--paleBlue);
          border-radius: 50%;
          opacity: .7;
        }

        .circle1{
          height: 100px;
          width: 100px;
          top: -50px;
          left: -50px;
        }

        .circle2{
          height: 150px;
          width: 150px;
          bottom: -80px;
          right: -70px;
          z-index: 1;
        }
      }
    }
  }