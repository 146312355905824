.log {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 150px;
  }
  
.log table {
    background-color: white;
    padding: 15px;
    padding-top: 30px ;
    padding-bottom: 30px;
    border-radius: 10px;
}
  
.log input{
    width: 240px;
    height: 20px;
    padding: 10px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    background-color: transparent;
    border-color: gray;
    border-radius: 5px;
    background-color: white;
}

.log input:invalid{
  color: red; 
  animation: shake 300ms;
 
}

@keyframes shake {
  25% {
    transform: translateX(4px);
  }
  50% {
    transform: translateX(-4px);
  }
  75% {
    transform: translateX(4px);
  }
}


  .log select {
    width: 280px;
    height: 40px;
    padding: 10px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    background-color: transparent;
    border-color: gray;
    border-radius: 5px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 24px;
    color: #333;
    font-size: 16px;
    font-weight: 500;
    font-family: Arial, sans-serif;
  }
  
  .log select:hover {
    border-color: #aaa;
  }
  
  .log select:focus {
    outline: none;
    border-color: black;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }
  
  .log input::content {
    border-style: none;
    border: none;
    background-color: rgb(186, 77, 77);
  }
  .log td {
    padding: 10px;
  }
  
  .log h2 {
    float: left;
    margin-left: 3%;
    padding: 7px;
    font-weight: 500;
    border-left: 5px;
    border-color: orange;
    border-left-style: solid;
  }
  
  .log button {
    transform: scaleX(1);
    transition-duration: 0.5s;
    width: 287px;
    height: 50px;
    background-color: rgb(56, 116, 227);
    color: white;
    border: none;
    border-radius: 5px;
  }
  
  .log button:hover {
    background-color: rgb(36, 79, 158);
  }
  
  .log button:active {
    transform: scaleX(0.9);
    transition-duration: 0.5s;
  }
  
  .log pre {
    color: rgb(153, 2, 2);
    height: 30px;
    font-size: larger;
    float: right;
  }
  
  .log span {
    float: left;
  }
  