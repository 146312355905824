@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap");
*{
    font-family: "Jost", sans-serif;
}

.btn-div{
    margin: 10px 150px 10px 120px;
    display: flex;
    justify-content :space-evenly;
    
}

.role-btn{
    /* height: 50px;
    width: auto;
    padding: 5px;
    border-radius: 10px;
    border: none;
    background-color: inherit;
    cursor: pointer;
    font-size: 20px;
    font-weight: 400;
    margin: 10px; */

  border-radius: 5px; /* rounding the corners of the button */
  padding: 10px 20px;
  background-color:inherit;
  color: black;
  border: none;
  transition: background-color 0.2s ease-in;
  text-decoration: none;
}

.role-btn.active {
    background-color: #038fde; 
    color: white;
  }
