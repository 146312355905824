:root {
  --primaryColor: #5993e2;
  --HoverColor: hsl(224, 92%, 76%);
  --paleBlue: #c2d6f5;
  --whiteColor: #fff;
  --blackColor: #000;
  --textColor: #333;
  --bgColor: #f5f5f5;
  --greyText: #777;
  --inputColor: hsl(330, 12%, 96%);
  /* Font and Typography */
  --biggestFontSize: 2.5rem;
  --h1FontSize: 1.5rem;
  --h2FontSize: 1.25rem;
  --h3FontSize: 1rem;
  --normalFontSize: 0.9rem;
  --smallFontSize: 0.8rem;
  --smallerFontSize: 0.7rem;
}

.sideBar {
  background: var(--whiteColor);
  width: 18%;
  height: 100%;
  gap: 2.5rem;
  overflow: auto;
}
.sideBar .logoDiv {
  padding: 1.5rem 1.5rem 0;
  cursor: pointer;
}
.sideBar .logoDiv img {
  max-width: 30px;
  margin-right: 0.5rem;
  border-radius: 2px;
  padding: 0.2rem;
}
.sideBar .logoDiv h2 {
  font-size: var(--h2FontSize);
  color: var(--primaryColor);
}
.sideBar .menuDiv .divTitle {
  font-size: var(--h3FontSize);
  color: var(--blackColor);
  padding: 0 1.5rem 1.5rem;
}
.sideBar .menuDiv .menuLists .listItem {
  padding: 0.2rem 1.5rem;
  width: 100%;
  position: relative;
}
.sideBar .menuDiv .menuLists .listItem::before {
  content: "";
  position: absolute;
  left: 0;
  width: 5px;
  height: 0%;
  bottom: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background: var(--primaryColor);
}
.sideBar .menuDiv .menuLists .listItem .menuLink {
  color: var(--greyText);
  font-weight: 500;
  transition: 0;
}
.sideBar .menuDiv .menuLists .listItem .menuLink .icon {
  margin-right: 0.5rem;
}
.sideBar .menuDiv .menuLists .listItem:hover .menuLink {
  color: var(--primaryColor);
  transition: 0;
}
.sideBar .menuDiv .menuLists .listItem:hover::before {
  height: 100%;
  transition: 0.3s ease;
}
.sideBar .sideBarCard {
  width: 100%;
  padding: 1rem;
  text-align: center;
  position: relative;
}
.sideBar .sideBarCard .icon {
  position: absolute;
  background: var(--HoverColor);
  border: 10px solid var(--whiteColor);
  font-size: 3rem;
  border-radius: 50%;
  top: -8px;
  right: 50%;
  transform: translateX(50%);
  z-index: 100;
}
.sideBar .sideBarCard .cardContent {
  position: relative;
  padding: 1rem;
  background: var(--HoverColor);
  border-radius: 10px;
  overflow: hidden;
}
.sideBar .sideBarCard .cardContent h3 {
  font-size: var(--h3FontSize);
  margin-top: 1rem;
  font-weight: 800;
  color: var(--blackColor);
}
.sideBar .sideBarCard .cardContent p {
  font-size: var(--normalFontSize);
  color: var(--textColor);
  padding-bottom: 1rem;
  font-weight: 500;
}
.sideBar .sideBarCard .cardContent .btn1 {
  position: relative;
  color: var(--textColor);
}
.sideBar .sideBarCard .cardContent .circle1, .sideBar .sideBarCard .cardContent .circle2 {
  position: absolute;
  background: var(--paleBlue);
  border-radius: 50%;
  opacity: 0.7;
}
.sideBar .sideBarCard .cardContent .circle1 {
  height: 100px;
  width: 100px;
  top: -50px;
  left: -50px;
}
.sideBar .sideBarCard .cardContent .circle2 {
  height: 150px;
  width: 150px;
  bottom: -80px;
  right: -70px;
  z-index: 1;
}/*# sourceMappingURL=sidebar.css.map */